<template>
    <b-modal 
        title="Удалить майнер" 
        v-model="show" 
        centered 
        hide-footer
        @hide="$emit('closed')"
    >
        
        <template v-if="miner">
            
            <validation-observer ref="form">
                <b-form>
                    
                    <b-form-group>
                        <span class="text-muted font-small-3">Контрактный номер майнера</span>
                        <b-form-input :value="miner.guuid" disabled class="cursor-not-allowed" />
                    </b-form-group>
                    
                    <b-form-group>
                        <span class="text-muted font-small-3">Название майнера</span>
                        <b-form-input :value="miner.machine.name" disabled class="cursor-not-allowed" />
                    </b-form-group>

                    <b-form-group >
                        <validation-provider #default="{ errors }" name="status" rules="required" >
                            <v-select
                              :options="types"
                              v-model="type"
                              label="name"
                              :clearable="false"
                              placeholder="Причина удаления майнера из контракта"
                            >
                            </v-select>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <b-form-group v-if="type !== null && type.value == 4" >
                        <validation-provider #default="{ errors }" name="enoch" rules="required" >
                            <b-form-textarea  placeholder="Укажите причину удаления майнера из контракта" v-model="enoch" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <b-form-group  style="height: auto;">
                        <validation-provider #default="{ errors }" name="serial_number" rules="required" >
                            <b-form-textarea  placeholder="Комментарий владельцу майнера" v-model="comment" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <small class="text-muted">
                        Физически контракт не удаляется из системы, а помечается, как архивный. <br>
                        Сбор статистики производиться не будет, но будет доступна собранная статистика до удаления майнера.
                    </small>
                    
                    <div class="d-flex justify-content-end mt-2">
                        <b-button @click="submit" variant="danger">Удалить</b-button>
                    </div>

                </b-form>
            </validation-observer>
        </template>
        
    </b-modal>
</template>

<script>

    import vSelect from 'vue-select'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required } from '@validations'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import MinerView from "@/components/MinerCard/small"
    
    export default {

        data() {
            return {
                required,

                types: [
                    { name: 'Возврат майнера', value: 1 },
                    { name: 'Trade-In', value: 2 },
                    { name: 'Окончание периода обслуживания', value: 3 },
                    { name: 'Иное', value: 4 },
                ],
                
                type: null,
                comment: null,
                enoch: null
            }
        },
        props: {
            contract: Number,
            miner: Object,
            show: Boolean
        },
        methods: {
            
            submit( args ) {
                this.$refs.form.validate().then( success => {
                    if( success ) {
                        this.$request.post("contracts/deleteMiner", {
                            contract: this.contract,
                            miner: this.miner.id,
                            type: this.type.value,
                            comment: this.comment,
                            enoch: this.enoch
                        }).then( result => {
                            this.$emit('onMinerDelete');
                            this.miner.status = 4;
                            this.$emit('closed');
                        })
                    }
                });
            },
            
            
        },
        components: {
            vSelect,
            MinerView,
            ValidationProvider,
            ValidationObserver
        },
        watch: {

        },
        mounted() {

        }

    }

</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>